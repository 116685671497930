import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faMapMarkerAlt,
  faMobileAlt
} from '@fortawesome/free-solid-svg-icons'
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons'

import Layout from "../components/layout"
import Logo from "../components/logo";

const IndexPage = () => (
  <Layout>
    <div className="min-h-screen flex flex-col items-center justify-center text-white font-sans">
      <div style={{ width: `200px`}} className="mb-4">
        <Logo />
      </div>
      <h1 className="font-sans mt-1 mb-1">Warsztat samochodowy</h1>
      <h2 className="font-sans font-light text-sm mb-1">Mechanika | Elektromechanika | Serwis klimatyzacji</h2>
      <p className="text-gray-500 mt-2 mb-2">Strona w budowie. Zapraszamy do kontaktu.</p>
      <address className="mt-2 mb-2">
        <FontAwesomeIcon icon={faMapMarkerAlt} /> ul. Bierutowska 19, Wrocław <br />
      </address>
      <div className="mt-2 mb-2">
        <a id="phone" href="tel:+48793173598" className="border rounded-md p-2">
          <FontAwesomeIcon icon={faMobileAlt} /> +48 793-173-598
        </a>
      </div>
      <div className="mt-4 mb-2">
        <a id="facebook" href="https://www.facebook.com/MD-Serwis-Samochodowy-111749760254804" className="border rounded-md p-2">
          <FontAwesomeIcon icon={faFacebookSquare} /> MD Serwis
        </a>
      </div>
    </div>
  </Layout>
)

export default IndexPage
